/*        COLORS          */
$color-white: #fff;
$color-black: #000;

// BLACK AND GREY
$color-primary: #F2FEE3;
$color-grey: #222222;

/*        FONTS         */

/* GLOBAL------------------------------------------------------------------------------------------ */
$global-comfortaa: 'Comfortaa-Regular';
$global-roboto: 'Roboto-Regular';

$global-font-weight-light: 200;
$global-font-weight-book: 300;
$global-font-weight-regular: 400;
$global-font-weight-medium: 500;
$global-font-weight-demibold: 600;
$global-font-weight-bold: 700;
$global-font-weight-black: 900;

/* BASE--------------------------------------------------------------------------------------------- */
$base-font-size: 16px;
$base-line-height: 40px;
$base-font-weight: $global-font-weight-regular;

/* ------------------------------------------------------------------------------------------------- */

$max-page-width: 410px;
$min-page-width: 290px;

/*          BREAKPOINTS           */
// values
$media-m1-value: 0;
$media-m2-value: 480;
$media-m3-value: 568;
$media-t1-value: 640;
$media-t2-value: 768;
$media-t3-value: 860;
$media-d1-value: 960;
$media-d2-value: 1024;
$media-d3-value: 1200;
$media-d4-value: 1320;

// min breakpoints
$media-m1-min: $media-m1-value + px;
$media-m2-min: $media-m2-value + px;
$media-m3-min: $media-m3-value + px;
$media-t1-min: $media-t1-value + px;
$media-t2-min: $media-t2-value + px;
$media-t3-min: $media-t3-value + px;
$media-d1-min: $media-d1-value + px;
$media-d2-min: $media-d2-value + px;
$media-d3-min: $media-d3-value + px;
$media-d4-min: $media-d4-value + px;

// max breakpoints
$media-m1-max: ($media-m2-value - 1) + px;
$media-m2-max: ($media-m3-value - 1) + px;
$media-m3-max: ($media-t1-value - 1) + px;
$media-t1-max: ($media-t2-value - 1) + px;
$media-t2-max: ($media-t3-value - 1) + px;
$media-t3-max: ($media-d1-value - 1) + px;
$media-d1-max: ($media-d1-value - 1) + px;
$media-d2-max: ($media-d2-value - 1) + px;
$media-d3-max: ($media-d3-value - 1) + px;
$media-d4-max: ($media-d4-value - 1) + px;