@import 'src/scss/variables';

.francais {

  max-width: 100%;
  justify-content: center;
  margin: 10px 10% 100px 10% !important;

  &__heading {
  font-style: normal;
  font-weight: normal;
  font-size: 2.25em;
  line-height: 2.5em;
  text-align: center;
  letter-spacing: -0.015em;
  color: $color-black;
  margin-top: 0px;
  }

  &__menu{
    width: 100%;
    height: 100px;
    text-align: center; 
    display: inline-flex;
  }

  &__headshot{
    text-align: center;
    padding-top: 0px;
  }

  &__img{
    width: 70%;
    max-height: 400px;
    object-fit: contain;
  }

  &__left{
    text-align: left;
    width: 50%;
    display: grid;
    margin-top: 14px;
  }

  &__right{
    display: inline-grid;
    grid-template-columns: 10ch 10ch 10ch 10ch auto;
    text-align: right;
    width: 50%;
    justify-content: right;
  }

  ul:hover, a:hover{
    color: #9ea4b2 !important;
  }

  a{
    text-decoration: none;
    color: black;
  }

  .languagePicker{
    float: right;
  }

  .dropdown{
    padding: 0px;
    font-size: 0.8em;
    width: inherit;
  }

  button{
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  .bio a {
    color: darkblue;
  }
  
  @media only screen and (max-width:$media-m1-max) {
    &__heading{
      font-size: 1.75em;
      line-height: 1em;
    }
    &__menu{
      width:75%;
    }
  }
}




