/*
 * Contains default styles for the site
 */

@import 'variables';

html,
body {
	height: 100vh;
	max-width: 100%;
	margin: 0;
	padding: 0;
	font-family: $global-comfortaa;
	font-size: $base-font-size;
	font-weight: $base-font-weight;
	line-height: $base-line-height;

	button {
		border: none;
		background: transparent;
	}
}

