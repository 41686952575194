@import 'src/scss/variables';

.menu_fr {

  width: 80%;
  height: 140px;
  margin: 10px 10% 0px 10% !important;
  display: inline-flex;

  .languagePicker {
    float: right;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  
  .right li {
    float: right;
    display: grid;
    width: min-content;
  }
  
  .right {
    width: 60%;
    // display: block;
  }
  
  .left {
    width: 40%;
  }
  
  .left li {
    float: left;
  }

  .left a {
    text-decoration: none;
    color: black;
  }

  li a:hover, li button:hover {
    color: #9ea4b2;
  }
  
  li a, li button {
    display: block;
    color: black;
    padding-top: 20px;
    padding-left: 24px;
    text-decoration: none;
    float: right;
    text-align: right;
    width: inherit;
  }

  ul li ul {
    display: block;
    text-align: center;
  }
  
  .menu {
    display: inline-flex;
    width: 100%;
  }
  
  .left a:hover {
    color: #9ea4b2;
  }

  .dropdown{
    padding: 0px;
    font-size: 0.8em;
    width: inherit;
    line-height: 15px;
    display: inline;
  }

  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  .menu_hidden {
    width: 100%;
    display: grid;
    justify-content: right;
    align-items: start;
  }

  .menu_hidden img {
    padding-top: 25px;
    width: 25px;
    float: right;
  }

  .menu_hidden .menu_items {
    float: right;
    text-align: right;
    font-size: 13px;
    line-height: 17px;
  }

  .menu_hidden .menu_items a {
    text-decoration: none;
    color: black;
  }
}

.languagePicker a {
  margin-right: 10%;
  margin-top: 5px;
  float: right;
  text-decoration: none;
  color: black
}

.languagePicker a:hover {
  color: #9ea4b2;
}