@font-face {
	font-family: Comfortaa-Regular;
	font-display: swap;
	src: url('fonts/Comfortaa-Regular.ttf');
}

@font-face {
	font-family: Roboto-Regular;
	font-display: swap;
	src: url('fonts/Roboto-Regular.ttf');
}